import { Routes } from "@angular/router";

export const pageRoutes: Routes = [
    {
        path: 'scripts',
        loadComponent: () => import('../../scripts/scripts.component').then((m) => m.ScriptsComponent),
    },
    // {
    //     path: 'variables',
    //     loadComponent: () => import('../../variables/variables.component').then((m) => m.VariablesComponent),
    // },
    {
        path: 'hooks',
        loadComponent: () => import('../../hooks/hooks.component').then((m) => m.HooksComponent),
    },
    {
        path: '',
        redirectTo: '/scripts',
        pathMatch: 'full' }
];
