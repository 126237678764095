import { HttpErrorResponse, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from "@angular/core";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { catchError, switchMap, take, throwError } from "rxjs";
import { filter } from "rxjs/operators";
import { ErrorPopupService } from "../../ui/error-popup/services/error-popup.service";

export const headersInterceptor: HttpInterceptorFn = (req, next, authenticationService = inject(AuthenticationService), errorPopupService = inject(ErrorPopupService)) => {
    if (!authenticationService.isChecking.value && !sessionStorage.getItem('db_updating')) {
        authenticationService.handleAuthenticationToken();
    }

    return authenticationService.isChecking.pipe(
        filter((checking: boolean) => !checking),
        take(1),
        switchMap(() => authenticationService.getBearerTokenObserver().pipe(
            filter((token: string) => !!token),
            take(1),
            switchMap((token: string) => {
                return next(modifyRequest(req, token)).pipe(
                    catchError((error: HttpErrorResponse) => {
                        if (error.status === 401) {
                            if (!authenticationService.isChecking.value) {
                                authenticationService.refreshToken();
                            }
                            return authenticationService.getBearerTokenObserver().pipe(
                                filter((newToken: string) => newToken !== token),
                                take(1),
                                switchMap((token: string) => {
                                    return next(modifyRequest(req, token)).pipe(
                                        catchError((error: HttpErrorResponse) => {
                                            if (error.status === 401) {
                                                errorPopupService.show('authorisation_error');
                                            }
                                            return throwError(() => error);
                                        })
                                    );
                                })
                            );
                        } else {
                            return throwError(() => error);
                        }
                    })
                );
            })
        ))
    )
};

function modifyRequest(request: HttpRequest<unknown>, token: string) {
    return request.clone({
        setHeaders: {
            "Authorization": `Bearer ${token}`,
        }
    })
}

